import React, { memo } from "react";

import { Spin } from "antd";

import { StyleWapper } from "./style";

export default memo(function Loading() {
  return (
    <StyleWapper>
      <Spin size="large"></Spin>
    </StyleWapper>
  );
});
