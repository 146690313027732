/*
 * @Author: 尚夏
 * @Date: 2021-06-02 15:34:24
 * @LastEditors: 尚夏
 * @LastEditTime: 2021-06-04 17:49:19
 * @Description:
 */

/* 门店 */
import { request } from '@/utils';

const shop = {
  /* 门店设置 */
  addStoreFoodShop: (data) => {     // 新建门店
    return request({url: '/sellerAdmin/food/addStoreFoodShop', data})
  },
  editStoreFoodShop: (data) => {    // 编辑门店
    return request({url: '/sellerAdmin/food/editStoreFoodShop', data})
  },
  getStoreList: (params) => {       // 获取门店列表
    return request({ url: '/sellerAdmin/food/storeFoodList', params, method: 'get'})
  },
  operateStoreFoodShop: (data) => { // 启用/禁用门店
    return request({ url: '/sellerAdmin/food/operateStoreFoodShop', data })
  },
  delStoreFoodShop: (data) => {     // 删除门店
    return request({ url: '/sellerAdmin/food/delStoreFoodShop', data })
  },
  getStoreFoodInfo: (data) => {     // 获取详情
    return request({ url: '/sellerAdmin/food/getStoreFoodInfo', data})
  },

  /* 预定排号 */
  getStoreBookingList: data => {    // 获取预定列表
    return request({ url: '/sellerAdmin/food/storeBookingList', data})
  },
  getManageStoreList: params => {   // 获取管理设置的门店列表
    return request({ url: '/sellerAdmin/food/getManageStoreList', params, method: 'get'})
  },
  /**
   * 开启关闭排号
   * @param {string} id 店铺id
   * @param {string} store_id 门店id
   */
  operateStoreRow: params => {
    return request({ url: '/sellerAdmin/food/operateStoreRow', params, method: 'get'})
  },
  /**
   * 开启关闭预定
   * @param {string} id 店铺id
   * @param {string} store_id 门店id
   */
  operateStoreBooking: params => {
    return request({ url: '/sellerAdmin/food/operateStoreBooking', params, method: 'get'})
  },
  // 新建排号设置
  setStoreRow: data => {
    return request({ url: '/sellerAdmin/food/setStoreRow', data })
  },
  // 获取排号设置详情
  getStoreRowDetail: data => {
    return request({ url: '/sellerAdmin/food/getStoreRowDetail', data })
  },
  // 新建预定设置
  setStoreBooking: data => {
    return request({ url: '/sellerAdmin/food/setStoreBooking', data })
  },
  // 获取预定设置详情
  getStoreBookingDetail: data => {
    return request({ url: '/sellerAdmin/food/getStoreBookingDetail', data })
  },


  /* 餐桌相关 */
  getTableGenreSelect: data => {
    return request({ url: '/sellerAdmin/table/getTableGenreSelect', data })
  },

};

export default shop;
