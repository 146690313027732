import styled from "styled-components";

const StyleWapper = styled.div`
  height: 52px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  flex-shrink: 0;
`;

export { StyleWapper };
