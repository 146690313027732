export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  localStorage.setItem(name, content);
};

// 动态图片路径
export const imageUrl = "https://img.douzishenghuo.com/";

/**
 * 拼接图片完成路径
 * @path 服务器给的图片路径
 */
export const getImageUrl = (path) => {
  path = path.replace('https://img.douzishenghuo.com/', '')
  // eslint-disable-next-line global-require
  return path ? imageUrl + path : "";
};

// 解析路由参数
export const paserQuery = (url) => {
  const index = url.indexOf("?");
  if (index === -1) return {};
  const tempQuery = url.split("?")[1];
  const questList = tempQuery.split("&");
  const queryData = {};
  questList.forEach((item) => {
    const tempList = item.split("=");
    if (tempList[0]) {
      // eslint-disable-next-line prefer-destructuring
      queryData[tempList[0]] = tempList[1];
    }
  });
  console.log(queryData)
  return queryData;
};

const baseUrls = {
  dev: "https://api.dev232.cn",
  pre: "https://api.yilian1.com",
  beta: "https://api.beta789.cn",
  prod: "https://api.douzishenghuo.com",
};

// 深复制对象
export const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const getUploadActionUrl = () => {
  // eslint-disable-next-line no-undef
  return baseUrls[API_MODE] + "/common/common/imgUpload";
};
