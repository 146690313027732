/*
 * @Author: 尚夏
 * @Date: 2021-06-02 11:08:31
 * @LastEditors: 尚夏
 * @LastEditTime: 2021-06-03 16:33:22
 * @Description:
 */
/* 公用接口 */
import { request } from '@/utils';

const common = {
  // 发送验证码
  sendCaptcha(data) {
    return request({ url: '/common/common/send', data })
  },
  getArea: (data) => { // 获取地区
    return request({url: '/common/common/getArea', data:data})
  },
  commonSearchDetail: (data) => {
    return request({url: '/common/common/searchDetail', data })
  },
  commonUploadImg: (data) => {
    return request({ url: '/common/common/imgUpload', data })
  }
};

export default common;
