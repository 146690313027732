import styled from "styled-components";

const StyleWapper = styled.div`
  width: 100%;
  height: 64px;
  min-height: 64px;
  background: #ffffff;
  font-weight: Regular;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
`;

export { StyleWapper };
