import { Redirect } from "react-router-dom";
import Loadable from "react-loadable";

import BaseLayout from "@/layout/BaseLayout";
import UserLayout from "@/layout/UserLayout";

import Login from "@/pages/login";
import Loading from "@/components/Loading";

const routes = [
  {
    path: "/user",
    component: UserLayout,
    routes: [
      {
        path: "/user",
        exact: true,
        render: () => <Redirect to="/user/login" />,
      },
      {
        path: "/user/login",
        name: "登录",
        component: Login,
      },
      {
        path: "/user/openCatering",
        name: "开通",
        component: Loadable({
          loader: () => import("@pages/openCatering"),
          loading: Loading,
        }),
      },
      {
        // path: "/404",
        component: Loadable({
          loader: () => import("@pages/status-pages/404.js"),
          loading: Loading,
        }),
      },
    ],
  },
  {
    path: "/",
    component: BaseLayout,
    routes: [
      {
        path: "/",
        render: () => <Redirect to="/shop" />,
        exact: true,
      },
      {
        path: "/shop",
        name: "门店",
        icon: "icon-shop",
        routes: [
          {
            path: "/shop",
            render: () => <Redirect to="/shop/setting" />,
            exact: true,
          },
          {
            path: "/shop/setting",
            title: "门店管理",
            render: () => <Redirect to="/shop/setting/list" />,
            exact: true,
          },
          {
            path: "/shop/setting/list",
            component: Loadable({
              loader: () => import("@pages/shop/setting/list"),
              loading: Loading,
            }),
            exact: true,
            name: "门店列表",
          },
          {
            path: "/shop/setting/list/addStore",
            component: Loadable({
              loader: () => import("@pages/shop/setting/list/AddStore"),
              loading: Loading,
            }),
            hideOnMenu: true,
            exact: true,
            name: "新建门店",
          },
          {
            path: "/shop/setting/list/editStore",
            component: Loadable({
              loader: () => import("@pages/shop/setting/list/EditStore"),
              loading: Loading,
            }),
            hideOnMenu: true,
            exact: true,
            name: "编辑门店",
          },
          // {
          //   path: "/shop/setting/exhibition",
          //   component: Loadable({
          //     loader: () => import("@pages/shop/setting/exhibition"),
          //     loading: Loading,
          //   }),
          //   name: "店铺展示",
          // },
          {
            path: "/shop/booking",
            exact: true,
            title: "门店设置",
            render: () => <Redirect to="/shop/booking/setting" />,
          },
          {
            path: "/shop/booking/setting",
            exact: true,
            component: Loadable({
              loader: () => import("@pages/shop/booking/setting"),
              loading: Loading,
            }),
            name: "管理设置",
          },
          {
            path: "/shop/booking/setting/reserve",
            component: Loadable({
              loader: () => import("@pages/shop/booking/setting/reserve"),
              loading: Loading,
            }),
            name: "预定设置",
            hideOnMenu: true,
          },
          {
            path: "/shop/booking/setting/numeral",
            component: Loadable({
              loader: () => import("@pages/shop/booking/setting/numeral"),
              loading: Loading,
            }),
            name: "排号设置",
            hideOnMenu: true,
          },
          {
            path: "/shop/booking/list",
            component: Loadable({
              loader: () => import("@pages/shop/booking/list"),
              loading: Loading,
            }),
            name: "预定列表",
          },
        ],
      },
      {
        path: "/cuisine",
        name: "菜品",
        icon: "icon-cuisine",
        routes: [
          {
            path: "/cuisine",
            render: () => <Redirect to="/cuisine/cuisineManagement" />,
            exact: true,
          },
          {
            path: "/cuisine/cuisineManagement",
            title: "菜品管理",
            render: () => (
              <Redirect to="/cuisine/cuisineManagement/menuManagement" />
            ),
            exact: true,
          },
          {
            path: "/cuisine/cuisineManagement/menuManagement",
            component: Loadable({
              loader: () =>
                import("@pages/cuisine/cuisine-management/menu-management"),
              loading: Loading,
            }),
            name: "菜单管理",
          },
          {
            path: "/cuisine/cuisineManagement/list",
            exact: true,
            component: Loadable({
              loader: () =>
                import("@pages/cuisine/cuisine-management/cuisine-list"),
              loading: Loading,
            }),
            name: "菜品列表",
          },
          {
            path: "/cuisine/cuisineManagement/list/cuisineCreate",
            component: Loadable({
              loader: () =>
                import(
                  "@pages/cuisine/cuisine-management/components/cuisine-create"
                ),
              loading: Loading,
            }),
            hideOnMenu: true,
            name: "新建",
          },
          {
            path: "/cuisine/cuisineManagement/list/cuisineEdit",
            component: Loadable({
              loader: () =>
                import(
                  "@pages/cuisine/cuisine-management/components/cuisine-edit"
                ),
              loading: Loading,
            }),
            hideOnMenu: true,
            name: "编辑",
          },
          {
            path: "/cuisine/packageManagement",
            title: "套餐管理",
            render: () => <Redirect to="/cuisine/packageManagement/list" />,
            exact: true,
          },
          {
            path: "/cuisine/packageManagement/list",
            exact: true,
            component: Loadable({
              loader: () =>
                import("@pages/cuisine/package-management/package-list"),
              loading: Loading,
            }),
            name: "套餐列表",
          },
          {
            path: "/cuisine/packageManagement/list/create",
            exact: true,
            hideOnMenu: true,
            component: Loadable({
              loader: () =>
                import(
                  "@pages/cuisine/package-management/components/create-combo"
                ),
              loading: Loading,
            }),
            name: "新建",
          },
          {
            path: "/cuisine/packageManagement/list/edit",
            exact: true,
            hideOnMenu: true,
            component: Loadable({
              loader: () =>
                import(
                  "@pages/cuisine/package-management/components/edit-combo"
                ),
              loading: Loading,
            }),
            name: "编辑",
          },
        ],
      },
      {
        path: "/diningTable",
        name: "餐桌",
        icon: "icon-dining-table",
        routes: [
          {
            path: "/diningTable",
            render: () => <Redirect to="/diningTable/areaTable" />,
            exact: true,
          },
          {
            path: "/diningTable/areaTable",
            title: "区域餐桌",
            render: () => (
              <Redirect to="/diningTable/areaTable/areaManagement" />
            ),
            exact: true,
          },
          {
            path: "/diningTable/areaTable/areaManagement",
            component: Loadable({
              loader: () =>
                import("@pages/dining-table/area-table/area-management"),
              loading: Loading,
            }),
            name: "区域管理",
          },
          {
            path: "/diningTable/areaTable/diningTableType",
            component: Loadable({
              loader: () =>
                import("@pages/dining-table/area-table/dining-table-type"),
              loading: Loading,
            }),
            name: "餐桌类型",
          },
          {
            exact: true,
            path: "/diningTable/areaTable/diningTableManagement",
            component: Loadable({
              loader: () =>
                import(
                  "@pages/dining-table/area-table/dining-table-management"
                ),
              loading: Loading,
            }),
            name: "餐桌管理",
          },
          {
            hideOnMenu: true,
            path: "/diningTable/areaTable/diningTableManagement/create",
            component: Loadable({
              loader: () =>
                import(
                  "@pages/dining-table/area-table/components/create-table"
                ),
              loading: Loading,
            }),
            name: "新建",
          },
          {
            hideOnMenu: true,
            path: "/diningTable/areaTable/diningTableManagement/edit",
            component: Loadable({
              loader: () =>
                import("@pages/dining-table/area-table/components/edit-table"),
              loading: Loading,
            }),
            name: "编辑",
          },
        ],
      },
      // {
      //   path: "/print",
      //   name: "打印",
      //   icon: "icon-print",
      //   routes: [],
      // },
      {
        path: "/order",
        name: "订单",
        icon: "icon-order",
        routes: [
          {
            path: "/order",
            render: () => <Redirect to="/order/orderList" />,
            exact: true,
          },
          {
            path: "/order/orderList",
            title: "订单列表",
            render: () => <Redirect to="/order/orderList/allOrder" />,
            exact: true,
          },
          {
            path: "/order/orderList/allOrder",
            component: Loadable({
              loader: () => import("@pages/order/order-list/all-order"),
              loading: Loading,
            }),
            name: "全部订单",
            exact: true,
          },
          {
            path: "/order/orderList/allOrder/orderDetail",
            component: Loadable({
              loader: () => import("@pages/order/order-list/order-detail"),
              loading: Loading,
            }),
            name: "详情",
            hideOnMenu: true,
          },
        ],
      },
      {
        path: "/setting",
        name: "设置",
        icon: "icon-setting",
        routes: [
          {
            path: "/setting",
            render: () => <Redirect to="/setting/accountAccess" />,
            exact: true,
          },
          {
            path: "/setting/accountAccess",
            title: "账号权限",
            render: () => (
              <Redirect to="/setting/accountAccess/accountManagement" />
            ),
            exact: true,
          },
          {
            path: "/setting/accountAccess/accountManagement",
            component: Loadable({
              loader: () =>
                import("@pages/setting/account-access/account-management"),
              loading: Loading,
            }),
            name: "账号管理",
            exact: true,
          },
          {
            path: "/setting/accountAccess/accountManagement/add",
            component: Loadable({
              loader: () =>
                import(
                  "@pages/setting/account-access/account-management/add/index.js"
                ),
              loading: Loading,
            }),
            name: "新增",
            hideOnMenu: true,
          },
          {
            path: "/setting/accountAccess/accountManagement/edit",
            component: Loadable({
              loader: () =>
                import(
                  "@pages/setting/account-access/account-management/add/index.js"
                ),
              loading: Loading,
            }),
            name: "编辑",
            hideOnMenu: true,
          },
          {
            path: "/setting/accountAccess/rankManagement",
            component: Loadable({
              loader: () =>
                import("@pages/setting/account-access/rank-management"),
              loading: Loading,
            }),
            name: "职级管理",
          },
        ],
      },
      {
        path: "*",
        component: Loadable({
          loader: () => import("@pages/status-pages/404.js"),
          loading: Loading,
        }),
      },
    ],
  },
  {
    path: "*",
    component: Loadable({
      loader: () => import("@pages/status-pages/404.js"),
      loading: Loading,
    }),
  },
];

export default routes;
