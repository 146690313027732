import cuisine from "./cuisine";
import common from "./common";
import shop from "./shop";

export const baseUrls = {
  dev: "https://api.dev232.cn",
  pre: "https://api.yilian1.com",
  beta: "https://api.beta789.cn",
  prod: "https://api.douzishenghuo.com",
};

export const APIS = {
  cuisine,
  common,
  shop,
};

// export { baseUrls, APIS };
