import styled from "styled-components";

const StyleWapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export { StyleWapper };
