import React, { memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { withRouter } from "react-router";

import { StyleWapper } from "./style";
import Menu from "@components/Menu";

const SecondSidebar = (props) => {
  // 当前选择的主路由
  const currentRoute = useSelector(
    (state) => state.common.currentRoute,
    shallowEqual
  );
  // 当前页面路径
  const currentPath = props.history.location.pathname;

  // 渲染二级菜单
  const renderMenu = (routes) => {
    return (
      <div className="list-container">
        {routes
          .filter((item) => item.name || item.title)
          .map((route, index) => {
            if (route.hideOnMenu) return null;
            // 检测当前匹配路由
            const checkActive = new RegExp(`^${route.path}`).test(currentPath);

            if (route.title) {
              return (
                <div className="item title" key={route.title}>
                  {route.title}
                </div>
              );
            }
            if (route.name) {
              return (
                <div
                  className={`item name ${checkActive ? "active" : ""}`}
                  key={route.name}
                  onClick={() => props.history.push(route.path)}
                >
                  {route.name}
                </div>
              );
            }
          })}
      </div>
    );
  };

  return <StyleWapper>{renderMenu(currentRoute.routes || [])}</StyleWapper>;
};

export default withRouter(SecondSidebar);
