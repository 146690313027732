import styled from "styled-components";

const StyleWapper = styled.div`
  width: 136px;
  height: 100%;
  background-color: #fff;
  margin-top: 1px;
  padding: 16px 8px;

  .list-container {
    display: flex;
    flex-direction: column;

    .item {
      height: 40px;
      user-select: none;
      padding: 0 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      margin-bottom: 1px;

      :hover {
        color: rgba(0, 0, 0, 0.8);
      }
    }

    .title {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
    }

    .name {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }

    .active {
      background: rgba(74, 142, 250, 0.1);
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;

export { StyleWapper };
