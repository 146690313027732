/*
 * @Author: 尚夏
 * @Date: 2021-05-13 11:11:20
 * @LastEditors: 尚夏
 * @LastEditTime: 2021-05-19 11:43:44
 * @Description: 二维码验证
 */

import React, { Component } from "react";
import "./less/index.less";
import { request } from "@/utils";
import { message } from "antd";
import store from "@/store";
import { setStore } from "@utils";

import { actions } from "@/store/modules/common";
import { APIS } from "@/config";

let timer = null;

export default class CodeLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshModel: false,
      code: "",
      qr_code: "",
    };
  }

  componentDidMount() {
    this.commonGetLoginQrCode(); //获取公共的我二维码
    this.setTimer();
  }

  componentWillUnmount() {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  }

  commonGetLoginQrCode() {
    request({ url: "common/common/getLoginQrCode" }).then((res) => {
      if (res.status_code == 0) {
        this.setState({
          code: res.data.code,
          qr_code: res.data.qr_code,
        });
      } else {
        message.error(res.message);
      }
    });
  }

  setTimer = () => {
    timer = setInterval(() => {
      this.loginVerifyCode();
    }, 3000);
  };

  loginVerifyCode = () => {
    let postData = {
      code: this.state.code,
    };
    request({ url: "sellerAdmin/login/verifyCode", data: postData }).then(
      async (res) => {
        if (!res) return;
        if (res.status_code == 0) {
          store.dispatch(
            actions.addUserInfoAction({
              storeId: res.data.store_id,
              userInfo: res.data.userInfo,
              token: res.data.tokenArr.token,
            })
          );
          localStorage.setItem("token", res.data.tokenArr.token);
          localStorage.setItem("store_id", res.data.store_id);
          sessionStorage.setItem("token", res.data.tokenArr.token);
          sessionStorage.setItem("store_id", res.data.store_id);
          sessionStorage.setItem("account", res.data.userInfo.account);
          sessionStorage.setItem("mobile", res.data.userInfo.mobile);
          sessionStorage.setItem("verify_status", res.data.verify_status);
          sessionStorage.setItem("open_interest", res.data.open_interest);

          setStore("userInfo", res.data.userInfo);

          // 请求门店列表数据
          const shopResult = await request({
            url: APIS.cuisine.storeFoodList,
            method: "get",
          });

          console.log("shopResult", shopResult);
          if (shopResult.status_code === 0) {
            if (shopResult.data && shopResult.data.length > 0) {
              store.dispatch(actions.updateShopList(shopResult.data));

              this.props.history.push("/");
              message.success(res.message);
            } else {
              this.props.history.push("/user/openCatering");
            }
          } else {
            this.props.history.push("/user/openCatering");
          }

          timer && clearInterval(timer);
        } else if (res.status_code == 1001) {
          // 跳出蒙层
          timer && clearInterval(timer);
          this.setState({
            refreshModel: true,
          });
        }
      }
    );
  };

  refresh = () => {
    this.setState({
      refreshModel: false,
    });
    this.commonGetLoginQrCode(); //重新获取二维码
    this.setTimer();
  };

  render() {
    let { code, qr_code, refreshModel } = this.state;
    let createMarkup = () => {
      return { __html: qr_code };
    };

    return (
      <div className="loginWay">
        <div className="loginType">
          <span className="status">
            验证码登录
            <span className="triangle"></span>
          </span>
        </div>
        <div className="title">扫码登录</div>
        <div>
          <div className="code">
            {refreshModel ? (
              <div className="qrModel flex_center" onClick={this.refresh}>
                <p className="code_failure">二维码已失效</p>
                <p className="re_loading">重新加载</p>
              </div>
            ) : null}
            <div
              dangerouslySetInnerHTML={createMarkup()}
              className="qr_code"
            ></div>
          </div>
          <div className="hint">打开商家版app扫一扫登录</div>
        </div>
      </div>
    );
  }
}
