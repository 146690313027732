import React, { memo, useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";

import { StyleWapper } from "./BaseLayoutStyle";
import Sidebar from "@components/Sidebar";
import GlobalHeader from "@components/GlobalHeader";
import SecondSidebar from "@components/SecondSidebar";
import { actions } from "@store/modules/common";
import Breadcrumb from "@components/Breadcrumb";
import { withRouter } from "react-router";

const BaseLayout = (props) => {
  const { route } = props;

  console.log("----", props);
  // 路由拆解
  const routers = [];
  // 内容高度
  const [windowHeight, setWindowHeight] = useState(document.body.clientHeight);

  const routesData = (routes = []) => {
    routes.map((item, index) => {
      item.routes ? routesData(item.routes) : routers.push(item);
    });
  };

  // 路由拆解  拆分为一层
  routesData(route.routes);

  // 使用redux 存储所有展开的路由
  const dispatch = useDispatch();

  // 初始化
  useEffect(() => {
    dispatch(actions.updateMatchRoutesAction(routers));
  }, []);

  useEffect(() => {
    function resize() {
      setWindowHeight(document.body.scrollHeight);
    }
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const token = localStorage.getItem("token");

  if (!token) return <Redirect to="/user/login" />;

  return (
    <StyleWapper height={windowHeight}>
      <Sidebar routes={route.routes} />
      <div className="baselayout-content-container">
        <GlobalHeader />
        <div className="baselayout-bottom-container">
          <SecondSidebar />
          <div id="scrollContainer" className="baselayout-content">
            <Breadcrumb />
            <div className="baselayout-pages-container">
              {renderRoutes(routers)}
            </div>
          </div>
        </div>
      </div>
    </StyleWapper>
  );
};

export default withRouter(memo(BaseLayout));
