import { createFromIconfontCN } from "@ant-design/icons";

// import { iconFontUrl } from "@/config";

// console.log("iconFontUrl", iconFontUrl);

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2577684_vc890sb3gvo.js",
});

export default IconFont;
