import axios from "axios";
import { useHistory } from "react-router";

import { message } from "antd";

const baseUrls = {
  dev: "https://api.dev232.cn",
  pre: "https://api.yilian1.com",
  beta: "https://api.beta789.cn",
  prod: "https://api.douzishenghuo.com",
};

const instance = axios.create({
  // baseURL
  // eslint-disable-next-line no-undef
  baseURL: baseUrls[API_MODE],
  method: "post",
  timeout: 2500,
});

// let tempToken = localStorage.getItem("token");
// // if(localStorage.getItem("token");
// if (!tempToken) {
//   window.history.pushState({}, "", "/user/login");
// }

function toLogin() {
  window.history.pushState({}, "", "/user/login");
  window.location.reload();
  const cancelToken = new axios.CancelToken();
  cancelToken();
}

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = localStorage.getItem("token");

    // if (!token) {
    //   toLogin();
    //   return null;
    // }

    config.headers["Authorization"] = token ? `Bearer ${token}` : null;
    config.params =
      config.method === "get" || config.method === "GET"
        ? Object.assign({}, config.params, {
            store_id: localStorage.getItem("store_id"),
          })
        : undefined;
    config.data =
      config.method === "post" || config.method === "POST"
        ? Object.assign({}, config.data, {
            store_id: localStorage.getItem("store_id"),
          })
        : undefined;
    return config;
  },
  (error) => {
    // Do something with request error
    message.error("message");
    return Promise.reject(error);
  }
);

// 响应拦截
instance.interceptors.response.use(
  (response) => {
    if (response.data.status_code === 0) {
      return response.data;
    }
    if (response.data.status_code === 424) {
      console.log("----", response);
      if (response.config.url !== "sellerAdmin/login/verifyCode") {
        message.error(response.data.message);
      }

      // window.history.pushState({}, "", "/user/login");
      return response.data;
    }
    return null;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      console.log("跳转到登录页");
      toLogin();
    }
    return Promise.reject(error);
  }
);

const request = (config) => {
  // return new Promise((resolve, reject) => {
  //   instance.request(config)
  // })
  return instance.request(config);
};

export default request;
