/* 餐饮接口列表 */

const cuisine = {
  // 菜单管理
  storeFoodList: "/sellerAdmin/food/storeFoodList", // 获取门店列表
  getMenuCategory: "/sellerAdmin/dishes/getMenuCategory", // 菜单管理->分类列表
  getMenuList: "/sellerAdmin/dishes/getMenuList",
  addMenuCategory: "/sellerAdmin/dishes/addMenuCategory", // 餐饮->菜品->菜单管理->添加菜单分类
  editMenuCategory: "/sellerAdmin/dishes/editMenuCategory", // 餐饮->菜品->菜单管理->编辑菜单分类
  delMenuCategory: "/sellerAdmin/dishes/delMenuCategory", // 餐饮->菜品->菜单管理->删除菜单分类
  getUseCategoryDishesList: "/sellerAdmin/dishes/getUseCategoryDishesList", // 餐饮->菜品->菜单管理->分类下可添加菜品列表
  getUseCategoryMenuList: "/sellerAdmin/dishes/getUseCategoryMenuList", // 餐饮->菜品->菜单管理->分类下可添加套餐列表
  addMenuJoinCombo: "/sellerAdmin/dishes/addMenuJoinCombo", // 餐饮->菜品->菜单管理->分类关联套餐
  addMenuJoinFoodDishes: "/sellerAdmin/dishes/addMenuJoinFoodDishes", // 餐饮->菜品->菜单管理->分类关联菜品
  dragMenuDishes: "/sellerAdmin/dishes/dragMenuDishes", // 餐饮->菜品->菜单管理->分类下菜品排序
  // 菜品列表
  getDishesList: "/sellerAdmin/dishes/getDishesList", //餐饮->菜品->菜品列表
  addFoodDishes: "/sellerAdmin/dishes/addFoodDishes", // 新建菜品
  operateFoodDishes: "/sellerAdmin/dishes/operateFoodDishes", // 停售 起售菜品
  delFoodDishes: "/sellerAdmin/dishes/delFoodDishes", // 删除菜品
  editFoodDishes: "/sellerAdmin/dishes/editFoodDishes", // 编辑菜品
  getFoodDishesDetail: "/sellerAdmin/dishes/getFoodDishesDetail", // 餐饮->菜品->菜品列表->编辑获取单条菜品信息
  delMenuJoinFoodDishes: "/sellerAdmin/dishes/delMenuJoinFoodDishes", //餐饮->菜品->菜单管理->删除分类下菜品\套餐
  // 套餐管理、
  getComboList: "/sellerAdmin/dishes/getComboList", // 套餐列表
  getComboDishesDetail: "/sellerAdmin/dishes/getComboDishesDetail", // 餐饮->套餐列表->获取套餐详情
  editComboDishes: "/sellerAdmin/dishes/editComboDishes", // 餐饮->套餐列表->编辑套餐
  getComboDishesList: "/sellerAdmin/dishes/getComboDishesList", // 餐饮->套餐列表->添加菜品列表
  addComboDishes: "/sellerAdmin/dishes/addComboDishes", // 餐饮->套餐列表->新建套餐
  operateCombo: "/sellerAdmin/dishes/operateCombo", // 餐饮->套餐->套餐列表->启售\停售套餐
  delFoodCombo: "/sellerAdmin/dishes/delFoodCombo", // 餐饮->套餐->套餐列表-> 删除套餐
  // 菜品单位相关餐饮->餐桌->餐桌列表
  goodsUnitSelectList: "/sellerAdmin/goodsUnit/selectList", // 商品管理->单位列表筛选
  goodsUnitDelete: "/sellerAdmin/goodsUnit/delete", // 商品管理-> 删除单位
  goodsUnitAdd: "/sellerAdmin/goodsUnit/add", // 商品管理->单位列表->新增单位
  goodsUnitUpdate: "/sellerAdmin/goodsUnit/update", // 商品管理->单位列表->新增单位
  // 属性列表相关
  extraAttributeSelectList: "/sellerAdmin/extraAttribute/selectList", // 商品管理->属性列表->选择
  extraAttributeOptionList: "/sellerAdmin/extraAttribute/optionList", // 商品管理->属性列表->属性值列表
  // 关联商品库相关
  goodsBrandSelectList: "/sellerAdmin/goodsBrand/selectList", // 商品管理->品牌列表筛选
  goodsLibrarySelectList: "/sellerAdmin/goods/librarySelectList", // 商品管理->品牌列表筛选
  goodsGroupSelectList: "/sellerAdmin/goodsGroup/selectList", // 商品管理->商品库分组列表选择
  getGoodsStandards: "/sellerAdmin/goods/getGoodsStandards", // 获取商品sku 数据
};

export default cuisine;
