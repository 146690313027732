import React, { memo } from "react";
import PropTypes from "prop-types";

import { StyleWapper } from "./style";
import IconFont from "@components/IconFont";

// menu item
const Item = (props) => {
  const useDefaultActive = props.useDefaultActive || false;
  // 合并样式
  const style = Object.assign(
    {},
    props.style,
    props.active ? props.activeStyle : {}
  );
  return (
    <div
      className={`menu-item`}
      onClick={props.onClick ? props.onClick : () => {}}
      style={style}
    >
      {useDefaultActive && props.active ? (
        <div className="active-line"></div>
      ) : null}
      <IconFont
        type={props.icon || ""}
        style={{ fontSize: props.iconSize || 0, marginRight: "8px" }}
      ></IconFont>
      <div>{props.children}</div>
    </div>
  );
};

// Menu
const Menu = (props) => {
  return (
    <StyleWapper direction={props.direction}>{props.children}</StyleWapper>
  );
};

// 类型限制
Menu.propTypes = {
  data: PropTypes.object,
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
};

// 默认值
Menu.defaultProps = {
  data: {},
  direction: "vertical",
};

Menu.Item = Item;

export default Menu;
