import styled from "styled-components";

const StyleWapper = styled.div`
  /* width: 100vw; */
  height: 100vh;
  background-color: #f6f6f6;
  display: flex;
  min-width: 1200px;
  min-height: 800px;
  overflow: hidden;

  .baselayout-content-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .baselayout-bottom-container {
      flex: 1;
      display: flex;
      flex-direction: row;

      .baselayout-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 10px;
        background-color: #fff;
        padding: 0 10px;
        border-radius: 4px;
        height: ${(props) =>
          props.height ? props.height - 74 + "px" : "auto"};
        overflow: auto;

        .baselayout-pages-container {
          flex: 1;
          padding-top: 16px;
        }
      }
    }
  }
`;

export { StyleWapper };
