const TYPES = {
  UPDATE_MATCH_ROUTES: "UPDATE_MATCH_ROUTES",
  UPDATE_CURRENT_ROUTE: "UPDATE_CURRENT_ROUTE",
  ADD_USERINFO: "ADD_USERINFO",
  UPDATE_SHOP_LIST: "UPDATE_SHOP_LIST",
  UPDATE_SHOP_TABS_ACTIVE_INDEX: "UPDATE_SHOP_TABS_ACTIVE_INDEX",
};

const actions = {
  // 将路由数据展开成一层
  updateMatchRoutesAction: (payload) => {
    return {
      type: TYPES.UPDATE_MATCH_ROUTES,
      payload,
    };
  },
  // 更新当前选中的一级菜单
  updateCurrentRouteAction: (payload) => {
    return {
      type: TYPES.UPDATE_CURRENT_ROUTE,
      payload,
    };
  },
  // 添加用户数据
  addUserInfoAction: (payload) => {
    return {
      type: TYPES.ADD_USERINFO,
      payload,
    };
  },
  // 更新门店列表数据
  updateShopList: (payload) => {
    return {
      type: TYPES.UPDATE_SHOP_LIST,
      payload,
    };
  },
  updateShopTabsActiveIndex: (payload) => {
    return {
      type: TYPES.UPDATE_SHOP_TABS_ACTIVE_INDEX,
      payload,
    };
  },
};

export { TYPES, actions };
