import { TYPES, actions } from "./actions";

const initState = {
  matchRoutes: [], // 展开成平级的所有路由
  currentRoute: {}, // 当前选中的路由
  userinfo: {}, // 用户信息
  shopList: [], // 门店列表
  shopTabsActiveIndex: 0,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case TYPES.UPDATE_MATCH_ROUTES:
      return Object.assign({}, state, { matchRoutes: action.payload });
    case TYPES.UPDATE_CURRENT_ROUTE:
      return Object.assign({}, state, { currentRoute: action.payload });
    case TYPES.ADD_USERINFO:
      return Object.assign({}, state, { userinfo: action.payload });
    case TYPES.UPDATE_SHOP_LIST:
      return Object.assign({}, state, { shopList: action.payload });
    case TYPES.UPDATE_SHOP_TABS_ACTIVE_INDEX:
      return Object.assign({}, state, { shopTabsActiveIndex: action.payload });
    default:
      return state;
  }
};

export { TYPES, actions };

export default reducer;
