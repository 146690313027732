import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { StyleWapper } from "./style";
import Menu from "@/components/Menu";
import logo from "@assets/common/logo.svg";
import { actions } from "@store/modules/common";

const Sidebar = (props) => {
  // 菜单路由数据
  const { routes = [] } = props;
  // 当前路由
  const currentPath = props.history.location.pathname;

  const dispatch = useDispatch();

  function renderMenu(routes) {
    return (
      <div className="menu-container">
        <Menu>
          {routes &&
            routes.map((item, index) => {
              if (item.hideOnMenu) return null;
              if (item.name) {
                // 检测当前匹配路由
                const checkActive = new RegExp(`^${item.path}`).test(
                  currentPath
                );
                // 更新当前选中数据
                if (checkActive) {
                  dispatch(actions.updateCurrentRouteAction(item));
                }
                return (
                  <Menu.Item
                    useDefaultActive
                    active={checkActive}
                    icon={item.icon}
                    iconSize={24}
                    key={item.name}
                    activeStyle={{ color: "#fff" }}
                    onClick={() =>
                      !checkActive ? props.history.push(item.path) : ""
                    }
                  >
                    {item.name}
                  </Menu.Item>
                );
              }
            })}
        </Menu>
      </div>
    );
  }

  return (
    <StyleWapper>
      <div className="logo-container">
        <img src={logo} alt="" />
      </div>
      <div className="menu-container">{renderMenu(routes)}</div>
    </StyleWapper>
  );
};

Sidebar.propTypes = {
  routes: PropTypes.array,
};

export default withRouter(Sidebar);
