import styled from "styled-components";

const StyleWapper = styled.div`
  width: 104px;
  height: 100%;
  background-color: #404454;

  .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 20px 30px 0 30px;
  }
`;

export { StyleWapper };
