/*
 * @Author: 尚夏
 * @Date: 2021-06-01 10:10:32
 * @LastEditors: 尚夏
 * @LastEditTime: 2021-06-02 11:30:14
 * @Description:
 */
import * as config from "./apis";
export const baseUrls = config.baseUrls;
export const APIS = config.APIS;
// 路径从这里导出有问题 暂时弃用
export const iconFontUrl = "//at.alicdn.com/t/font_2577684_vc890sb3gvo.js";
export const name = "餐饮后台";

// export { iconFontUrl, name };
export { default as routes } from "./routers";
