import styled from "styled-components";

const StyleWapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) =>
    props.direction === "vertical" ? "column" : "row"};

  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5) !important;
  line-height: 20px;

  .menu-item {
    display: flex;
    cursor: pointer;
    padding: 15px;
    align-items: center;
    user-select: none;
    &:hover {
      color: rgba(255, 255, 255, 1) !important;
    }
  }

  .active-line {
    width: 4px;
    height: 34px;
    background-color: #4a8efa;
    position: absolute;
    left: 0;
  }
`;

export { StyleWapper };
