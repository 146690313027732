import React, { memo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import { Breadcrumb as AntdBreadcrumb } from "antd";

import { StyleWapper } from "./style";

const Breadcrumb = (props) => {
  // 拆平为一级的所有路由
  const [matchRoutes, currentRoute] = useSelector(
    (state) =>
      [state.common.matchRoutes || [], state.common.currentRoute || []] || [],
    shallowEqual
  );
  // 当前页面路由
  const currentPath = props.history.location.pathname;
  // 计算所有匹配到的路由
  const matchs = matchRoutes
    .filter((item) => item.name || item.title)
    .map((route, index) => {
      const reg = new RegExp(`^${route.path}`);
      if (reg.test(currentPath)) {
        return route;
      }
    })
    .filter((item) => item !== undefined);
  // 添加主路由
  matchs.unshift(currentRoute);

  return (
    <StyleWapper>
      <AntdBreadcrumb style={{ fontSize: "14px" }}>
        {matchs.map((route, index) => {
          return (
            <AntdBreadcrumb.Item key={index}>
              <Link to={route.path ? route.path : "/"}>
                {route.name || route.title}
              </Link>
            </AntdBreadcrumb.Item>
          );
        })}
      </AntdBreadcrumb>
    </StyleWapper>
  );
};

export default withRouter(memo(Breadcrumb));
