import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { renderRoutes } from "react-router-config";

import "./App.less";
import store from "@store";
import { routes } from "@/config";
import zhCN from "antd/lib/locale/zh_CN";
import { ConfigProvider } from "antd";
import { paserQuery } from "@/utils/utils";

function App() {
  // 获取从餐饮后台跳转过来 携带的数据
  const params = paserQuery(window.location.search);
  console.log("params", params);
  if (params.token && params.store_id) {
    console.log("代码执行");
    localStorage.setItem("token", params.token);
    localStorage.setItem("store_id", params.store_id);
  }

  return (
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
