/*
 * @Author: 尚夏
 * @Date: 2021-05-12 15:26:54
 * @LastEditors: 尚夏
 * @LastEditTime: 2021-05-17 17:57:43
 * @Description:
 */
import React, { Component } from "react";
import "./less/index.less";
import CodeLogin from "./CodeLogin.js";
import PhoneLogin from "./PhoneLogin.js";

import loginLogo from "@/assets/login/loginLogo.png";
import codeCheck from "@/assets/login/codeCheck.png";

export default class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginStatusCode: true, // 登录方式是否扫码登录
    };
  }
  componentDidMount() {}

  handleChangeType = () => {
    const { loginStatusCode } = this.state;
    this.setState({
      loginStatusCode: !loginStatusCode,
    });
  };

  render() {
    let { loginStatusCode } = this.state;

    return (
      <div className="loginContainer">
        <div className="header">
          <img src={loginLogo} alt="豆子生活商家后台" />
          豆子生活商家后台
        </div>
        <div className="loginCon">
          {loginStatusCode ? (
            <img
              className="checkType"
              onClick={this.handleChangeType}
              src={codeCheck}
              alt="切换登录方式"
            />
          ) : (
            <img
              className="checkType"
              onClick={this.handleChangeType}
              src={codeCheck}
              alt="切换登录方式"
            />
          )}
          <div>
            {loginStatusCode ? (
              <CodeLogin {...this.props} />
            ) : (
              <PhoneLogin {...this.props} />
            )}
          </div>
        </div>
        <div className="footer">
          Copyright@2018-2021 www.douzishenghuo.com.All Rights
          Reserved浙江亿链网络科技有限公司版权所有 浙ICP备B2-20191019
        </div>
      </div>
    );
  }
}
