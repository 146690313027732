/*
 * @Author: 尚夏
 * @Date: 2021-05-13 11:11:29
 * @LastEditors: 尚夏
 * @LastEditTime: 2021-05-19 11:42:44
 * @Description:
 */
import React, { Component } from "react";
import "./less/index.less";
import { Form, Input, Button, message } from "antd";
import { setStore, request } from "@utils";
import store from "@/store";
import phoneInput from "@/assets/login/phoneInput.png";
import codeInput from "@/assets/login/codeInput.png";
import { APIS } from "@/config";
import { actions } from "@/store/modules/common";

const layout = {
  wrapperCol: { span: 24 },
};
const phoneReg = new RegExp("^[1][3-8][0-9]{9}$");

let timer = null;
class PhoneLogin extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      showCount: false,
      count: 60,
    };
  }

  onFinish = (values) => {
    request({
      url: "seller/login/sellerLogin",
      data: { mobile: values.mobile, code: values.code },
    }).then(async (res) => {
      if (res.status_code === 0) {
        store.dispatch({
          type: "ADD_USERINFO",
          data: {
            storeId: res.data.userInfo.store_id,
            userInfo: res.data.userInfo,
            token: res.data.tokenArr.token,
          },
        });
        localStorage.setItem("token", res.data.tokenArr.token);
        localStorage.setItem("store_id", res.data.userInfo.store_id);
        sessionStorage.setItem("token", res.data.tokenArr.token);
        sessionStorage.setItem("store_id", res.data.userInfo.store_id);
        sessionStorage.setItem("account", res.data.userInfo.account);
        sessionStorage.setItem("mobile", res.data.userInfo.mobile);
        // sessionStorage.setItem("verify_status", res.data.verify_status);
        // sessionStorage.setItem("open_interest", res.data.open_interest);
        setStore("userInfo", res.data.userInfo);

        // 请求门店列表数据
        const shopResult = await request({
          url: APIS.cuisine.storeFoodList,
          method: "get",
        });

        console.log("shopResult", shopResult);
        if (shopResult.status_code === 0) {
          if (shopResult.data && shopResult.data.length > 0) {
            store.dispatch(actions.updateShopList(shopResult.data));

            this.props.history.push("/");
            message.success(res.message);
          } else {
            this.props.history.push("/user/openCatering");
          }
        } else {
          this.props.history.push("/user/openCatering");
        }

        if (timer) {
          clearInterval(timer);
          timer = null;
        }
      } else {
        message.error(res.message);
      }
    });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // 计时器
  handelTimer = () => {
    const { count } = this.state;
    let number = count;
    timer = setInterval(() => {
      if (number === 0) {
        if (timer) {
          clearInterval(timer);
          timer = null;
          this.setState({
            showCount: false,
            count: 60,
          });
        }
      } else {
        number--;
        this.setState({
          count: number,
        });
      }
    }, 1000);
  };

  // 获取验证码
  getCode = () => {
    const { showCount } = this.state;
    if (!showCount) {
      this.formRef.current.validateFields(["mobile"]).then((values) => {
        this.setState({
          showCount: true,
        });
        this.handelTimer();
        // console.log(values, 'ssssaaa')
        request({
          url: "common/common/send",
          data: {
            mobile: values.mobile,
            type: "user_verify_",
          },
        }).then((res) => {
          if (res.status_code === 0) {
            message.success("验证码发送成功");
          } else {
            message.error(res.message);
          }
        });
      });
    }
  };

  render() {
    const { showCount, count } = this.state;
    return (
      <div className="loginWay">
        <div className="loginType">
          <span className="status">
            扫码登录更便捷
            <span className="triangle"></span>
          </span>
        </div>
        <div className="title">验证码登录</div>
        <div className="phone_form">
          <Form
            ref={this.formRef}
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
          >
            <Form.Item
              name="mobile"
              rules={[
                { required: true, message: "请填写11位的手机号" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || phoneReg.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("请填写11位的手机号"));
                  },
                }),
              ]}
            >
              <Input
                className="inputClass"
                allowClear
                placeholder="注册时填写的手机号"
                prefix={<img className="icon" src={phoneInput} alt="" />}
              />
            </Form.Item>
            <Form.Item
              name="code"
              rules={[{ required: true, message: "请填写验证码" }]}
            >
              <Input
                className="inputClass"
                placeholder="请填写验证码"
                prefix={<img className="icon" src={codeInput} alt="" />}
                suffix={
                  <span className="phone_code" onClick={this.getCode}>
                    {showCount ? count + "s" : "获取验证码"}
                  </span>
                }
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login_btn">
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default PhoneLogin;
